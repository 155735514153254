import type { WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId } from '../../constants';

type ComponentBuilderFn = Parameters<
  WidgetBuilder['configureConnectedComponents']
>[1];

const COLLAPSABLE_ELEMENT_IDS = [
  ElementId.HeaderContainer,
  ElementId.MenuContainer,
];

const configureComponentAsCollapsable: ComponentBuilderFn = (builder) => {
  builder.behavior().set({ preventHide: false });
};

const configureWidgetGFPP = (builder: WidgetBuilder) => {
  builder.gfpp().set('help', {
    id: '1216e503-61fb-479f-a318-9af886dd2769',
  });
  builder.configureConnectedComponents(
    'horizontalMenu1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: '4c8ba651-aa3f-45c2-82ae-abf3f856b3e6',
      });
    },
  );
  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
  builder.gfpp().set('widgetPlugins', {
    behavior: 'HIDE',
  });
};

const configurePageWidget = (builder: WidgetBuilder) => {
  builder.behavior().set({ removable: false, duplicatable: false });
};

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  configurePageWidget(widgetBuilder);
  configureWidgetGFPP(widgetBuilder);

  COLLAPSABLE_ELEMENT_IDS.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(
      elementId,
      configureComponentAsCollapsable,
    );
  });
};
