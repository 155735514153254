import { getManageAndNavigatePanelInitialData } from './utils';
import { createCustomPanelOnClick } from '../../common/create-panel';
import { toMonitored } from '../../../editor/services/monitor';
import { withRetry } from '../../../editor/services';
import { WidgetName } from '../../../constants';

import type {
  ManageAndNavigatePanelInitialData,
  OpenManageAndNavigatePanelOptions,
} from '../../../types';

export const openManageAndNavigatePanel = async ({
  editorSDK,
  event,
  t,
}: OpenManageAndNavigatePanelOptions) => {
  const initialData = await toMonitored(
    'manage-and-navigate-panel.get-initial-data',
    () =>
      withRetry(() => getManageAndNavigatePanelInitialData(editorSDK), {
        methodName: 'getManageAndNavigatePanelInitialData',
      }),
  );

  return createCustomPanelOnClick<ManageAndNavigatePanelInitialData>({
    title: t('app.settings.manage.menu.panel.title'),
    editorSDK,
    panelComp: 'ManageAndNavigatePanel',
    height: 521,
    width: 288,
    helpId: 'f99c3e77-0303-46f3-a5eb-23e565ecdcb8',
    initialData,
    widgetName: WidgetName.Widget1,
  })(event);
};
