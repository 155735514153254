import type { FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId } from '../../constants';
import { isProfilePageBobWidgetInstalled } from '../../editor/services/page-ref';

type ComponentBuilderFn = Parameters<
  WidgetBuilder['configureConnectedComponents']
>[1];

const getCollapsableElementsIds = async (editorSDK: FlowEditorSDK) => {
  if (await isProfilePageBobWidgetInstalled(editorSDK)) {
    return [ElementId.HeaderContainer, ElementId.MenuContainer];
  }

  return [ElementId.HeaderContainer];
};

const configureComponentAsCollapsable: ComponentBuilderFn = (builder) => {
  builder.behavior().set({ preventHide: false });
};

const configureWidgetGFPP = (builder: WidgetBuilder) => {
  // TODO: get help ids

  /* builder.gfpp().set('help', {
    id: '1216e503-61fb-479f-a318-9af886dd2769',
  });
  builder.configureConnectedComponents(
    'horizontalMenu',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: '4c8ba651-aa3f-45c2-82ae-abf3f856b3e6',
      });
    },
  );*/
  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
  builder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
  builder.gfpp().set('stretch', { behavior: 'HIDE' });
};

const configurePageWidget = (builder: WidgetBuilder) => {
  builder.behavior().set({ removable: false, duplicatable: false });
};

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
) => {
  configurePageWidget(widgetBuilder);
  configureWidgetGFPP(widgetBuilder);

  const collapsableElementsIds = await getCollapsableElementsIds(editorSDK);

  collapsableElementsIds.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(
      elementId,
      configureComponentAsCollapsable,
    );
  });
};
