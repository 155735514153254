import type {
  ComponentRef,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';
import type { gfppOriginStrings } from '@wix/platform-editor-sdk';

import { EventType } from '@wix/platform-editor-sdk';

import { GfppAction, ReferralInfo } from '../../../../constants';
import { openMemberSettingsBM } from '../../../../editor/services';
import { openDisplayPanel, openPresetPanel } from '../../../common/panels';
import {
  openProfileManageAndNavigatePanel,
  openSettingsManageAndNavigatePanel,
  openSettingsPageDisplayPanel,
  openSettingsPagePresetPanel,
} from '../../panels/open-panels';

const handleWidgetGfppEvent = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
    gfppOrigin: gfppOriginStrings;
  }>,
) => {
  switch (event.detail.id) {
    case GfppAction.OpenLayoutPanel: {
      return openPresetPanel(
        editorSDK,
        event,
        flowAPI.translations.t as TFunction,
      );
    }
    case GfppAction.OpenDisplayPanel: {
      return openDisplayPanel(flowAPI, editorSDK, event);
    }
    case GfppAction.OpenMemberPrivacySettingsBM: {
      return openMemberSettingsBM(
        editorSDK,
        ReferralInfo.MembersPrivacyManageMemberPrivacyGfpp,
      );
    }
    case GfppAction.OpenSettingsLayoutPanel: {
      return openSettingsPagePresetPanel(
        editorSDK,
        event,
        flowAPI.translations.t as TFunction,
      );
    }
    case GfppAction.OpenSettingsDisplayPanel: {
      return openSettingsPageDisplayPanel(flowAPI, editorSDK, event);
    }
    default: {
      break;
    }
  }
};

export const handleComponentGfppEvent = (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
  }>,
) => {
  switch (event.detail.id) {
    case GfppAction.OpenManageAndNavigatePanel:
      return openProfileManageAndNavigatePanel({
        editorSDK,
        event,
        t: flowAPI.translations.t as TFunction,
      });
    case GfppAction.OpenSettingsManageAndNavigatePanel:
      return openSettingsManageAndNavigatePanel({
        editorSDK,
        event,
        t: flowAPI.translations.t as TFunction,
      });
    default:
      break;
  }
};

export const addWidgetGfppClickedListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener(EventType.widgetGfppClicked, (event) =>
    handleWidgetGfppEvent(editorSDK, flowAPI, event),
  );

  await editorSDK.addEventListener(EventType.componentGfppClicked, (event) =>
    handleComponentGfppEvent(editorSDK, flowAPI, event),
  );
};
