import type { EditorReadyFn } from '@wix/yoshi-flow-editor';

import {
  monitoredTransactionFactory,
  toMonitored,
} from '../../editor/services/monitor';
import { uninstallIfProvisionedWithoutMembersArea } from '../common/uninstallation';
import { installMemberSettingsPage, installProfilePage } from './installation';
import { isSplitPlatformAppValid } from './utils';
import { addEventsListeners } from './listeners';

export const editorReady: EditorReadyFn = (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const originInfo = options.origin.info as Record<string, any> | undefined;
  const isSocialInstallation = Boolean(originInfo?.isSocialInstallation);

  const installAction = () => {
    const context = {
      editorSDK,
      options,
      appDefinitionId,
      flowAPI,
    };

    return Promise.all([
      installMemberSettingsPage(context),
      isSocialInstallation ? installProfilePage(context) : Promise.resolve(),
    ]);
  };

  const editorReadyAction = async () => {
    if (!options.firstInstall) {
      const { uninstalled } = await uninstallIfProvisionedWithoutMembersArea(
        editorSDK,
      );

      if (uninstalled || !(await isSplitPlatformAppValid(editorSDK))) {
        return;
      }

      // TODO: Implement fixers for Settings and Profile page tabs.
    }

    if (options.firstInstall) {
      await monitoredTransaction('ma-split.install', installAction);
    }

    await addEventsListeners(editorSDK, flowAPI);
  };

  return toMonitored('ma-split.editorReady', editorReadyAction);
};
